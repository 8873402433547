import * as React from 'react';

import Drawer from 'antd/lib/drawer';

import { IProps as FilterComponentProps } from '@common/react/components/UI/FiltersComponent/FiltersComponent';

import '@app/scss/components/mobileFilters.scss';

const MobileFilters: React.FC<FilterComponentProps> = (props) => {
	const {
		filters, tooltipValues, apply, filtersClean, disabled = false, buttonClassName, triggerNode,
	} = props;
	const [open, setOpen] = React.useState<boolean>(false);
	const count = filters.reduce((res, filter) => (filter() ? res + 1 : res), 0);

	const clean = React.useCallback(() => {
		if (filtersClean) {
			filtersClean();
		}
	}, [filtersClean]);

	const onClose = React.useCallback(() => setOpen(false), []);
	const onOpen = React.useCallback(() => setOpen(true), []);

	const trigger = triggerNode || <button
		onClick={onOpen}
		type="button"
		className={`btn btn-default filters-component-button ${buttonClassName || ''}`}
	>
		<i className="fa fa-filter" aria-hidden="true" />
	</button>;

	return (
		<div
			className="filters-component-container pull-right"
		>
			{disabled
				? <>
					<button type="button" disabled={disabled} className="btn btn-default filters-component-button">
						<i className="fa fa-filter" aria-hidden="true" />
					</button>
				</>
				: <>
					<Drawer
						onClose={onClose}
						placement="bottom"
						height="calc(100% - 170px)"
						open={open}
						title={null}
						closable={false}
						rootClassName="drawer-filters-container"
					>
						{tooltipValues}
						{(apply || filtersClean)
							? <div className="filters-apply-btn-container">
								{filtersClean
									? (
										<button
											type="button"
											className={`btn btn-danger ${apply ? 'mr10' : ''}`}
											disabled={count === 0}
											onClick={clean}
										>
											Clear
										</button>
									) : null
								}
								{apply
									? <button type="button" className="btn btn-primary" onClick={onClose}>Apply</button> : null
								}
							</div> : null
						}
					</Drawer>
					{trigger}
				</>}
			{count > 0 && !disabled
			&& <div
				className="badge dashboard-container-user-block__badge"
				onClick={clean}
			>
				<span>{count}</span>
				<i className="fa fa-close" />
			</div>
			}
		</div>
	);
};

export default MobileFilters;
