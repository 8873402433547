import React from 'react';

import * as Yup from 'yup';
import * as moment from 'moment';
import Select from 'antd/lib/select';
import Modal from 'antd/lib/modal';

import Button from '@common/react/components/Forms/Button';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { nonEmptyArray, notNullValidator, simpleStringValidator } from '@common/react/utils/validationHelpers';
import { getPopupContainer } from '@common/react/components/Utils/Utils';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { TimeSpan } from '@commonTuna/react/components/UI/DayTimeSelectModal/DayTimeSelectModal';
import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';

import { Appointment, AppointmentProcedure } from '@app/objects/Appointment';
import { User } from '@app/objects/User';
import { Location } from '@app/objects/Location';
import { Doctor, DoctorBookedTime, DoctorProcedure } from '@app/objects/Doctor';
import LoginForm from '@app/components/UI/ScheduleAppointmentModal/LoginForm';
import DoctorBookedTimeBlockTable from '@app/components/UI/DoctorBookedTimeBlock/DoctorBookedTimeBlock';
import { dateToUtcString, getAppContainer, getLocationName } from '@app/components/Utils';

interface ComponentProps {
	showDoctor?: boolean;
	patient: User | null;
	doctorFixed?: boolean;
	doctor?: Doctor;
	afterSubmit?: (items?: Array<any>) => void;
	loadInvites?: () => void;
	scheduledTime?: number;
	selectedLocation?: Location | null;
	locationItems?: Array<Location>;
	onModalClose?: () => void;
	asNearestDate?: boolean;
	doctorProcedures?: Array<DoctorProcedure>;
	doctorBookedTime?: DoctorBookedTime;
	daysRange?: number;
	showMobile?: boolean;
	doctors?: Array<Doctor>;
	locationId?: number;
}

export interface SelectedTimeIndex {
	key: string;
	start: string;
}

interface FormValues {
	id: number | null;
	firstName: string | null;
	lastName: string | null;
	patientId: number | null;
	patient: User | null;
	scheduledTime: number | null;
	procedures: Array<AppointmentProcedure>;
	location: Location | null;
	locationId: number | null;
	doctorProcedureIds: Array<number>;
	duration: string | null;
	notes: string;
	doctor: Doctor | null;
	doctorProcedure: DoctorProcedure | null;
	selectedTimeIndex: SelectedTimeIndex | undefined;
}

const getDuration = (timeSpan: string): string | null => {
	const timeArr = timeSpan.split(':');
	if (timeArr.length === 3) {
		const hours = +timeArr[0] ? `${+timeArr[0]} h ` : '';
		const minutes = `${+timeArr[1]} m`;
		return `${hours}${minutes}`;
	}
	return null;
};

export const getMinutesFromDuration = (duration: string) => {
	const [h, m] = duration.split(':');

	return +(h || 0) * 60 + +(m || 0);
};

const { success, error } = Modal;

const Option = Select.Option;

function strToTimeSpan(str: string): TimeSpan {
	const numArr = str.split(':').map(Number);
	return {
		hour: numArr[0],
		minute: numArr[1],
	};
}

function clearListFields<T>(items: Array<T>, fields: Array<string>) {
	if (!items) {
		return;
	}

	const newValues = {};
	fields.forEach((field) => newValues[field] = null);

	return items.map((item: T) => ({
		...item,
		...newValues,
	}));
}

export const dateStringRequiredValidator = Yup.string().matches(/([0-9]{2}-[0-9]{2}-[0-9]{4})/, 'Invalid date').required();

const validationSchemaWithPatientCreating = Yup.object().shape({
	locationId: notNullValidator.required(),
	scheduledTime: notNullValidator.required(),
	duration: simpleStringValidator,
	procedures: nonEmptyArray('Procedures should not be empty'),
});

export const getDaysCount = () => {
	if (typeof window === 'undefined') return 3;
	if (window.innerWidth >= 1920) return 7;
	if (window.innerWidth >= 1770) return 6;
	if (window.innerWidth >= 1580) return 5;
	if (window.innerWidth >= 1366) return 4;
	return 3;
};

export const getDaysCountMobile = () => {
	if (typeof window === 'undefined') return 4;

	const count = Math.floor((window.innerWidth - 160) / 82);

	return count > 7 ? 7 : count < 2 ? 2 : count;
};

const getRowsCount = () => {
	if (typeof window === 'undefined' || window.innerHeight >= 1000) {
		return 11;
	}

	return Math.ceil((window.innerHeight - 650) / 50);
};

const FormHandler: React.FC<{ formik, patient, doctor, formRef}> = ({
	formik, patient, doctor, formRef,
}) => {
	React.useEffect(() => {
		formik.setValues((prev) => ({
			...prev,
			patientId: patient?.id || -1,
			patient: patient || null,
			firstName: patient?.firstName || '',
			lastName: patient?.lastName || '',
		}));
	}, [patient]);

	React.useEffect(() => {
		if (doctor) {
			formik.setValues((prev) => ({
				...prev,
				doctor: doctor || null,
				doctorId: doctor?.id,
			}));
		}
	}, [doctor]);

	React.useEffect(() => {
		if (formik) {
			formRef.current = formik;
		}
	}, [formik]);

	return <></>;
};

const ScheduleAppointment: React.FC<ComponentProps> = ({
	showDoctor,
	patient,
	doctor,
	doctorFixed = false,
	afterSubmit,
	loadInvites,
	scheduledTime,
	selectedLocation,
	locationItems,
	onModalClose,
	asNearestDate,
	doctorProcedures,
	doctorBookedTime,
	daysRange,
	showMobile,
	doctors,
	locationId,
}) => {
	const [locations, setLocations] = React.useState<Array<Location>>(locationItems ?? []);
	const [doctorBookedTimes, setDoctorBookedTimes] = React.useState<DoctorBookedTime | undefined>(doctorBookedTime);
	const [procedures, setProcedures] = React.useState<Array<DoctorProcedure>>(doctorProcedures || []);
	const [isLoading, setLoading] = React.useState<boolean>(false);
	const [visible, setVisible] = React.useState(false);
	const [duration, setDuration] = React.useState<number>(15);
	const [invites, setInvites] = React.useState<Array<BaseInvite>>([]);
	const [companyTemplateInvite, setCompanyTemplateInvite] = React.useState<BaseInvite | null>(null);
	const [days, setDays] = React.useState(daysRange || (showMobile ? getDaysCountMobile : getDaysCount)());
	const [rowCount, setRowCount] = React.useState(getRowsCount());
	const [isMobile, setIsMobile] = React.useState(typeof window !== 'undefined' ? window.innerWidth < 768 : false);
	const formRef = React.useRef<any>();
	const { request } = useApplicationContext();

	React.useEffect(() => {
		const resize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	const initialValues = React.useMemo(() => {
		const values = {} as FormValues;
		return ({
			...{} as Appointment,
			patientId: patient?.id || -1,
			patient: patient || null,
			firstName: patient?.firstName || '',
			lastName: patient?.lastName || '',
			doctor: doctor || null,
			doctorId: doctor?.id,
			duration: procedures[0]?.duration || null,
			scheduledTime: scheduledTime && !asNearestDate
				? scheduledTime
				: values.scheduledTime || null,
			notes: values.notes || '',
			location: selectedLocation || (values.location || locations.length > 0 ? locations[0] : null),
			locationId: locationId || (selectedLocation
				? selectedLocation.id
				: values.locationId || locations.length > 0 ? locations[0].id : -1),
			procedures: values.procedures || [{
				id: -1,
				deleted: false,
				doctorProcedure: null,
				startProcedureTime: scheduledTime || values.scheduledTime || null,
				appointmentId: null,
				count: 1,
			} as unknown as AppointmentProcedure],
			doctorProcedure: values.doctorProcedure || null,
			doctorProcedureIds: values?.doctorProcedureIds ? values.doctorProcedureIds.length > 0 ? values.doctorProcedureIds : [] : [],
			selectedTimeIndex: undefined,
		});
	}, [patient, doctor?.id, scheduledTime, selectedLocation]);

	const handleClose = React.useCallback(() => {
		setVisible(false);
		onModalClose && onModalClose();
	}, []);

	const afterLogin = () => {
		handleClose();
		Modal.confirm({
			title: `You wanted to create an appointment with ${doctor?.nameEn} ${
				dateToUtcString(formRef.current?.values.scheduledTime, 'hh:mm a')}?`,
			content: '',
			className: 'schedule-confirm',
			icon: <></>,
			okText: 'Yes',
			okType: 'primary',
			cancelText: 'No',
			okButtonProps: {
				className: 'btn btn-primary',
			},
			cancelButtonProps: {
				className: 'btn btn-default',
			},
			getContainer: getAppContainer,
			onOk() {
				formRef.current?.submitForm();
			},
			onCancel: () => {
				fillDoctorProcedureFields(procedures[0]);
				formRef.current?.setFieldValue('scheduledTime', scheduledTime && !asNearestDate
					? scheduledTime
					: null);
				if (locations.length) {
					formRef.current?.setFieldValue('locationId', locations[0].id);
					formRef.current?.setFieldValue('location', locations[0]);
				}
			},
		});
	};

	const onSave = React.useCallback(() => {
		success({
			content: <>
				<i className="fa fa-check-circle" style={{ color: '#52c41a', fontSize: 22 }} />
				{' '}
				Appointment has been successfully created.
			</>,
			wrapClassName: 'success-modal',
			icon: null,
		});
	}, []);

	const loadLocations = (doctorId?: number) => {
		const locationData = {
			text: '',
			doctorId,
		};
		request('locationList', locationData)
			.then((response: any) => {
				setLocations(response.list);
				if (formRef.current && formRef.current.values.locationId === -1 && response.list.length > 0) {
					formRef.current?.setFieldValue('locationId', response.list[0].id);
					formRef.current?.setFieldValue('location', response.list[0]);
				}
			})
			.catch((err) => {
				error({
					title: 'Error',
					content: `Something went wrong. ${err}`,
				});
			});
	};

	const fillDoctorProcedureFields = (doctorProcedure: DoctorProcedure) => {
		formRef.current?.setFieldValue('doctorProcedureId', +doctorProcedure.id);
		formRef.current?.setFieldValue('doctorProcedureIds', [+doctorProcedure.id]);
		formRef.current?.setFieldValue('doctorProcedure', doctorProcedure);
		formRef.current?.setFieldValue('procedures', [{
			doctorProcedure,
			doctorProcedureId: +doctorProcedure.id,
			duration: formRef.current?.values.duration,
			startProcedureTime: formRef.current?.values.scheduledTime,
			appointmentId: -1,
		}]);
		formRef.current?.setFieldValue('doctor', doctorProcedure.doctor);
		formRef.current?.setFieldValue('duration', doctorProcedure.duration);
	};

	const loadProcedures = (doctorId?: number, locationId?: number) => {
		const proceduresData = {
			text: '',
			doctorId,
			locationId,
			withProcedure: true,
			withDoctor: true,
			withAppointmentType: true,
			published: true,
			hasGlobalProcedure: true,
			withGlobalProcedure: true,
		};

		request('doctorProcedureList', proceduresData)
			.then((response: any) => {
				const list = response.list.filter((p) => p.procedure?.globalProcedureId);
				setProcedures(list);
				if (formRef.current && list.length > 0) {
					fillDoctorProcedureFields(response.list[0]);
				}
				formRef.current?.setFieldValue('locationId', locationId);
			})
			.catch((err) => {
				error({
					title: 'Error',
					content: `Something went wrong. ${err}`,
				});
			});
	};

	const handleResize = () => {
		setDays((showMobile ? getDaysCountMobile : getDaysCount)());
		setRowCount(getRowsCount());
	};

	const endDate = React.useMemo(() => doctorBookedTimes && moment(doctorBookedTimes.endDate).subtract(7 - days, 'days'), []);

	React.useEffect(() => {
		if (!locationItems) {
			loadLocations(doctor?.id);
		}

		if (formRef.current?.values) {
			if (procedures.length > 0) {
				fillDoctorProcedureFields(procedures[0]);
			} else {
				loadProcedures(formRef.current?.values.doctor?.id || doctor?.id, formRef.current?.values.locationId || undefined);
			}
		}

		if (doctorBookedTimes) {
			const keys = Object.keys(doctorBookedTimes.values);

			if (!doctorBookedTimes.nearestTime && endDate && keys.every((key) => {
				if (moment(key) <= endDate) {
					return doctorBookedTimes.values[key].length === 0;
				}
				return true;
			})) {
				const nearestTimeKey = keys.find((key) =>
					(doctorBookedTimes.values[key]?.length > 0 ? doctorBookedTimes.values[key].some((value) => !value.booked) : false));

				if (nearestTimeKey) {
					const tempNearestTime = moment(nearestTimeKey);
					if (endDate && tempNearestTime > endDate) {
						doctorBookedTimes.nearestTime = tempNearestTime.valueOf();
					}
				}
			}

			const keysForRemove = endDate && Object.keys(doctorBookedTimes.values).map((key) => {
				if (moment(key) > endDate) {
					return key;
				}
				return '';
			});

			keysForRemove && keysForRemove.length > 0 && keysForRemove.map((key) => {
				if (key) {
					delete doctorBookedTimes.values[key];
				}
			});
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	React.useEffect(() => {
		if (scheduledTime && !asNearestDate && selectedLocation && formRef.current) {
			formRef.current?.setFieldValue('scheduledTime', scheduledTime);
			formRef.current?.setFieldValue('location', selectedLocation);
			formRef.current?.setFieldValue('locationId', selectedLocation.id);
		}

		if (scheduledTime && selectedLocation) {
			loadProcedures(formRef.current?.values.doctor?.id || doctor?.id, selectedLocation.id);
		}
	}, [scheduledTime, selectedLocation, asNearestDate]);

	const value = React.useMemo(() => (doctorBookedTimes && endDate && {
		...doctorBookedTimes,
		endDate: endDate.valueOf(),
	}), [doctorBookedTimes, endDate]);

	return <>
		<ItemProvider
			id={-1}
			type="patient"
			readonly={false}
			add={initialValues}
			validationSchema={validationSchemaWithPatientCreating}
			clearForSubmit={(values) => {
				const data = {
					...values,
					patient: null,
					doctor: null,
					location: null,
					room: null,
					appointmentType: null,
					scheduledTime: values.scheduledTime!,
					procedures: clearListFields(values.procedures, ['doctorProcedure', 'appointment', 'room', 'doctorPayer']),
				};
				return {
					id: -1,
					doctorPath: doctor?.portalPathEn,
					appointments: [
						{
							...data,
							scheduledTime: values.scheduledTime,
						},
					],
				} as any;
			}}
		>
			<ItemEditor
				showMessages={false}
				beforeSubmit={(values: any, actions, submit) => {
					if (values.patient) {
						setLoading(true);
						submit()
							.then((response: any) => {
								onSave();
								setLoading(false);
								setVisible(false);

								// Try remove time block if appointment successfully scheduled
								if (doctorBookedTimes && values.selectedTimeIndex) {
									const current = { ...doctorBookedTimes };
									current.values[values.selectedTimeIndex.key] = current.values[values.selectedTimeIndex.key]
										.filter((q) => q.start !== values.selectedTimeIndex!.start);
									setDoctorBookedTimes(current);
								}

								const newAppointment = response.appointments[0];

								if (newAppointment.invites?.length > 0) {
									const companyInvite = newAppointment.invites.find((inv) => inv.isCompanyTemplate && !inv.answered);
									if (companyInvite) {
										setCompanyTemplateInvite(companyInvite);
									}
									setInvites(newAppointment.invites.filter((inv) => !inv.isCompanyTemplate));
								}

								afterSubmit && afterSubmit(newAppointment.invites);
							})
							.catch((err: string) => {
								setLoading(false);
								error({
									title: 'Error',
									content: err,
								});
							});
					} else {
						setVisible(true);
					}
					return false;
				}}
				edit={(formik) => <div style={{ padding: 10 }}>
					<FormHandler formik={formik} patient={patient} doctor={doctor} formRef={formRef} />
					<div className="">
						{doctors ? <>
							<label htmlFor="doctorId">Doctor*</label>
							<Select
								id="doctorId"
								style={{ marginBottom: 15 }}
								placeholder="Doctor..."
								popupClassName="general-form-select-dropdown"
								defaultValue={0}
								getPopupContainer={getPopupContainer}
								value={formik.values.doctor?.id ?? 0}
								onChange={(value: number, option: any) => {
									const selectedDoctor = option?.item || doctors?.find((q) => q.id === value);

									if (selectedDoctor && doctor?.id !== selectedDoctor?.id) {
										loadProcedures(value, formik.values.locationId);

										formik.setFieldValue('doctor', selectedDoctor);

										formik.setFieldValue('doctorProcedure', null);
										formik.setFieldValue('scheduleTime', null);
									}
								}}
							>
								{doctors?.map((doctor, index) =>
									<Option
										value={doctor.id}
										key={doctor.id}
										item={doctor}
									>
										{doctor.nameEn}
									</Option>)}
							</Select>
						</> : null}
						<label htmlFor="procedureId">Procedure* </label>
						<ProcedureSelectComponent formik={formik} procedures={procedures} doctorFixed={doctorFixed} />
						<label htmlFor="locationId">
							Location*
						</label>
						<LocationSelectComponent formik={formik} locations={locations} loadProcedures={loadProcedures} />
					</div>
					{formik.values.locationId && formik.values.doctor?.id && formik.values.duration && (
						<div className="mb10" style={{ maxWidth: '100%' }}>
							<DoctorBookedTimeBlockTable
								doctorId={formik.values.doctor.id}
								locations={locations as Array<Location>}
								days={days}
								rowCount={rowCount}
								interval={formik.values.duration ? getMinutesFromDuration(formik.values.duration) : undefined}
								selectedTime={formik.values.scheduledTime || undefined}
								initStartDay={(asNearestDate && scheduledTime) || undefined}
								selectLocationById={formik.values.locationId || undefined}
								onSelect={(time: number, location: Location, asNearestTime?: boolean, selectedTimeIndex?: SelectedTimeIndex) => {
									formik.setFieldValue('scheduledTime', time);
									if (formik.values.procedures?.length) {
										formik.setFieldValue('procedures[0].startProcedureTime', time);
									}
									formik.setFieldValue('selectedTimeIndex', selectedTimeIndex);
									/* formik.setFieldValue('location', location);
									formik.setFieldValue('locationId', location.id); */
								}}
								value={value}
								withoutLocationButtons
							/>
						</div>
					)}
					<div className="text-center">
						<Button
							className="btn btn-primary general-form-button"
							isLoading={isLoading}
							disabled={!formik.values.scheduledTime || !formik.values.doctorProcedure}
						>
							Schedule Appointment
						</Button>
					</div>
				</div>}
			/>
		</ItemProvider>
		<LoginForm visible={visible} handleClose={handleClose} afterLogin={afterLogin} />
	</>;
};

export default ScheduleAppointment;

interface ProcedureSelectComponentProps {
	formik: any;
	procedures: Array<DoctorProcedure>;
	doctorFixed: boolean;
}

const ProcedureSelectComponent: React.FC<ProcedureSelectComponentProps> = ({ formik, procedures, doctorFixed }) => {
	return <Select
		id="procedureId"
		style={{ marginBottom: 15 }}
		value={formik.values.doctorProcedure?.id || formik.values.doctorProcedureIds[0]}
		placeholder="Procedure..."
		getPopupContainer={getPopupContainer}
		disabled={!formik.values.locationId}
		onSelect={(value, option) => {
			formik.setFieldValue('doctorProcedureId', +value);
			formik.setFieldValue('doctorProcedureIds', [+value]);
			formik.setFieldValue('doctorProcedure', option.props.item);
			formik.setFieldValue('procedures', [{
				doctorProcedure: option.props.item,
				doctorProcedureId: +value,
				duration: formik.values.duration,
				startProcedureTime: formik.values.scheduledTime,
				appointmentId: -1,
			}]);
			formik.setFieldValue('doctor', option.props.item.doctor);
			formik.setFieldValue('duration', option.props.item.duration);
		}}
		onChange={(value = -1) => {
			if (value === -1) {
				formik.setFieldValue('doctorProcedureId', null);
				formik.setFieldValue('doctorProcedure', null);
			}
		}}
	>
		{procedures.map((doctorProcedure) => {
			const addInfo = `${doctorFixed ? ''
				: `${doctorProcedure!.doctor?.nameEn || '-'}, `}${getDuration(doctorProcedure!.duration) || '0 m'}`;
			return (
				<Option
					key={doctorProcedure.id.toString()}
					{...{
						value: doctorProcedure.id,
						title: doctorProcedure.procedure.globalProcedure?.name,
						item: doctorProcedure,
					}}
				>
					{doctorProcedure.procedure.globalProcedure?.name}
					{' '}
					(
					{addInfo}
					)
				</Option>
			);
		})}
	</Select>;
};

interface LocationSelectComponentProps {
	formik: any;
	locations: Array<Location>;
	loadProcedures: (doctorId?: number, locationId?: number) => void;
}

const LocationSelectComponent: React.FC<LocationSelectComponentProps> = ({ formik, locations, loadProcedures }) => {
	return <Select
		id="locationId"
		style={{ marginBottom: 15 }}
		placeholder="Location..."
		popupClassName="general-form-select-dropdown"
		defaultValue={0}
		getPopupContainer={getPopupContainer}
		value={formik.values.location?.id ?? 0}
		onChange={(value: number) => {
			const location = locations.find((q) => q.id === value);

			if (location && formik.values.locationId !== location?.id) {
				loadProcedures(formik.values.doctor?.id, location?.id);

				formik.setFieldValue('location', location);

				formik.setFieldValue('doctorProcedure', null);
				formik.setFieldValue('scheduleTime', null);
			}
		}}
	>
		{locations?.map((location, index) =>
			<Option
				value={location.id}
				key={location.id}
			>
				{getLocationName(location)}
			</Option>)}
	</Select>;
};

interface AutocompleteDoctorComponentProps {
	formik: any;
	loadLocations: (doctorId?: number) => void;
	loadProcedures: (doctorId?: number, locationId?: number) => void;
}

const AutocompleteDoctorComponent: React.FC<AutocompleteDoctorComponentProps> = ({ formik, loadLocations, loadProcedures }) => {
	return <Autocomplete
		type="doctorList"
		disabled={!!formik.values.doctor}
		renderTitle={(item: Doctor) => item.nameEn}
		value={formik.values.doctor ? formik.values.doctor.nameEn : ''}
		placeholder="Doctor..."
		renderOption={(autocompleteItem) => {
			return ({
				key: autocompleteItem.id.toString(),
				value: autocompleteItem.nameEn,
				title: autocompleteItem.nameEn,
				item: autocompleteItem,
				label: autocompleteItem.nameEn,
			});
		}}
		onSelect={(value, option) => {
			formik.setFieldValue('doctorProcedureId', -1);
			formik.setFieldValue('doctorProcedureIds', []);
			formik.setFieldValue('doctorProcedure', null);
			formik.setFieldValue('procedures', []);
			formik.setFieldValue('doctor', option.props.item);
			loadLocations(option.props.item.id || -1);
			if (option.props.item.id > 0 && formik.values.locationId) {
				loadProcedures(option.props.item.id, formik.values.locationId);
			}
		}}
		onChange={(value = '') => {
			if (value === '') {
				formik.setFieldValue('doctorProcedureId', null);
				formik.setFieldValue('doctorProcedure', null);
				formik.setFieldValue('doctor', null);
				loadLocations();
			}
		}}
		params={{ locationId: formik.values.locationId }}
		loadOnFocus
	/>;
};
