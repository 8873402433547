import React from 'react';

import moment, { Moment } from 'moment';

import Message from 'antd/lib/message';

import LogoLoader, { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import { request } from '@app/components/Api';
import { Location } from '@app/objects/Location';
import DoctorBookedTimeBlockTable from '@app/components/UI/DoctorBookedTimeBlock/DoctorBookedTimeBlockTable';
import { DoctorBookedTime } from '@app/objects/Doctor';
import {
	filterDisabledTimes,
	getStartDayInitial,
	resolveBookedTimes,
} from '@app/utils/DoctorBookedTimeBlock/DoctorBookedTimeBlock';
import { SelectedTimeIndex } from '@app/components/UI/ScheduleAppointmentModal/ScheduleAppointment';

interface ComponentProps {
	doctorId: number;
	onSelect?: (time: number, location: Location, asNearestDate?: boolean, selectIndex?: SelectedTimeIndex) => void;
	locations: Array<Location>;
	value?: DoctorBookedTime;
	className?: string;
	days?: number;
	rowCount?: number;
	onlyWorkingDays?: boolean;
	selectLocationById?: number;
	selectedTime?: number;
	initStartDay?: number;
	withoutLocationButtons?: boolean;
	withHeader?: boolean;
	interval?: number;
	hideDisabledTimes?: boolean;
	nearestDateRedirect?: boolean;
}

const DoctorBookedTimeBlock: React.FC<ComponentProps> = ({
	doctorId,
	onSelect,
	locations,
	value,
	className,
	days,
	rowCount,
	onlyWorkingDays = false,
	selectedTime,
	selectLocationById,
	withoutLocationButtons,
	withHeader,
	interval: propsInterval,
	hideDisabledTimes = true,
	nearestDateRedirect = false,
	initStartDay,
}) => {
	const [selectedLocation, setSelectedLocation] = React.useState<Location>(selectLocationById
		? (locations.find((q) => q.id === selectLocationById) ?? locations[0])
		: locations[0]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [range, setRange] = React.useState<number>(days ?? 7);
	const [interval, setInterval] = React.useState<number>(propsInterval || 0);
	const [startDay, setStartDay] = React.useState<number>(initStartDay || getStartDayInitial(range, selectedTime));
	const [startDate, setStartDate] = React.useState<number>(startDay);
	const [endDate, setEndDate] = React.useState<number>(startDate);
	const [bookedTimeRes, setBookedTimeRes] = React.useState<DoctorBookedTime | null>(value || null);
	const [nearestTime, setNearestDate] = React.useState<number | null>(null);
	const [solvedNearestTime, setSolvedNearestDate] = React.useState<number | null>(nearestTime);
	const nearestDate = solvedNearestTime || nearestTime;

	React.useEffect(() => {
		if (propsInterval !== interval) {
			setInterval(propsInterval || 0);
		}
	}, [propsInterval]);

	React.useEffect(() => {
		setBookedTimeRes(value || null);
	}, [value]);

	const loadTimes = (from: number, interval: number, locationId: number, days?: number) => {
		setLoading(true);
		request<DoctorBookedTime>('getDoctorBookedTime', {
			id: doctorId,
			locationId,
			interval,
			from,
			daysRange: days || range,
			onlyWorkingDays,
		}).then((res) => {
			setBookedTimeRes(res);
			setStartDate(res.startDate);
			setEndDate(res.endDate);
			setNearestDate(res.nearestTime);
		}).catch((message) => {
			Message.error(message);
		}).finally(() => {
			setLoading(false);
		});
	};

	React.useEffect(() => {
		if (value && JSON.stringify(value) !== JSON.stringify(bookedTimeRes)) {
			setBookedTimeRes(value);
			setStartDate(value.startDate);
			setEndDate(value.endDate);
			setNearestDate(value.nearestTime);
		}
	}, [value]);

	React.useEffect(() => {
		if (value && (!selectedLocation || value.locationId === selectedLocation.id) && (Object.values(value.values).length || value.nearestTime)) {
			setStartDate(value.startDate);
			setEndDate(value.endDate);
			setNearestDate(value.nearestTime);
		} else {
			selectedLocation?.id && loadTimes(startDate, interval, selectedLocation.id);
		}
	}, [interval]);

	React.useEffect(() => {
		let newSelectedLocation; let
			newRage;
		if (selectLocationById && selectLocationById !== selectedLocation?.id) {
			newSelectedLocation = selectLocationById
				? (locations.find((q) => q.id === selectLocationById) ?? locations[0])
				: locations[0];
			setSelectedLocation(newSelectedLocation);
		}

		if (days && days !== range) {
			newRage = days;
			setRange(newRage);
		}

		if (newSelectedLocation || newRage) {
			loadTimes(startDate, interval, (newSelectedLocation || selectedLocation)?.id, newRage || days);
		}
	}, [selectLocationById, days]);

	const bookedTime = React.useMemo(() => {
		if (!bookedTimeRes) {
			return null;
		}

		const resolvedBookedTime = resolveBookedTimes(bookedTimeRes, propsInterval || interval);
		return hideDisabledTimes ? filterDisabledTimes(resolvedBookedTime) : resolvedBookedTime;
	}, [propsInterval, interval, bookedTimeRes]);

	React.useEffect(() => {
		const dates = Object.keys(bookedTime?.values || {})
			.map((key) => (bookedTime?.values[key].length ? key : null));
		const dateIndex = dates.findIndex((time) => time !== null);

		setSolvedNearestDate(dateIndex + 1 > range ? +moment(dates[dateIndex]) : null);
	}, [range, nearestDate, bookedTime]);

	const onSelectLocation = (location: Location) => {
		setSelectedLocation(location);

		loadTimes(startDate, interval, location.id);
	};

	const onSelectTime = (day: Moment, time: Moment, nearestDate?: number, selectedTimeIndex?: SelectedTimeIndex) => {
		onSelect && onSelect(
			nearestDate || day.add(time.hours(), 'hours').add(time.minutes(), 'minutes').valueOf(),
			selectedLocation,
			!!nearestDate,
			selectedTimeIndex,
		);
	};

	const selectNextWeek = () => {
		loadTimes(moment(endDate).add('day', 1).valueOf(), interval, selectedLocation.id);
	};

	const onSelectPreviousWeek = () => {
		loadTimes(moment(startDate).add('day', -1).valueOf(), -interval, selectedLocation.id);
	};

	const onNearestTimeClick = () => {
		const solvedNearestDate = Object.keys(bookedTime?.values || {})
			.map((key) => (bookedTime?.values[key].length ? key : null))
			.find((time) => time !== null);
		const date = solvedNearestDate ? +moment(solvedNearestDate) : nearestDate;
		if (date) {
			if (nearestDateRedirect) {
				const time = moment.utc(date);
				onSelectTime(time, time, +time.hours(new Date().getHours()));
			} else {
				loadTimes(date, interval, selectedLocation.id);
			}
		}
	};

	if (!bookedTime) {
		return <LogoLoaderPage />;
	}

	return <div className={`is-relative ${nearestDate ? 'nearest-container' : ''}`}>
		<DoctorBookedTimeBlockTable
			range={range}
			bookedTime={bookedTime}
			onSelectTime={(day: Moment, time: Moment, selectIndexTime?: SelectedTimeIndex) =>
				onSelectTime(day, time, undefined, selectIndexTime)}
			onSelectNextWeek={selectNextWeek}
			onSelectPreviousWeek={onSelectPreviousWeek}
			startDate={startDate}
			locations={locations}
			locationId={selectedLocation.id}
			onSelectLocation={onSelectLocation}
			rowCount={rowCount}
			selectedTime={selectedTime}
			withHeader={withHeader}
			className={className}
			withoutLocationButtons={withoutLocationButtons}
			nearestTime={nearestDate}
			onNearestTimeClick={onNearestTimeClick}
		/>
		{loading
			&& <div className="doctor-booked__loader">
				<LogoLoader size={45} />
			</div>
		}
	</div>;
};

export default DoctorBookedTimeBlock;
