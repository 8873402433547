import React from 'react';

import moment, { Moment } from 'moment';

import { DoctorBookedTime } from '@app/objects/Doctor';
import { Location } from '@app/objects/Location';

import '@app/scss/components/doctorBookedTimeBlockTable.scss';
import DoctorBookedTimeBlockHeader from '@app/components/UI/DoctorBookedTimeBlock/DoctorBookedTimeBlockHeader';
import { SelectedTimeIndex } from '@app/components/UI/ScheduleAppointmentModal/ScheduleAppointment';

interface ComponentProps {
	bookedTime: DoctorBookedTime;
	onSelectTime: (day: Moment, time: Moment, selectedTimeIndex?: SelectedTimeIndex) => void;
	onSelectPreviousWeek: () => void;
	onSelectNextWeek: () => void;
	startDate: number;
	locationId: number;
	locations?: Array<Location>;
	onSelectLocation?: (location: Location) => void;
	className?: string;
	rowCount?: number;
	selectedTime?: number;
	withoutLocationButtons?: boolean;
	withHeader?: boolean;
	nearestTime: number | null;
	onNearestTimeClick?: () => void;
	range: number;
}

const getInitialCount = (bookedTime: DoctorBookedTime, offset: number, selectedTime?: number): number => {
	if (!selectedTime) {
		return 0;
	}

	for (const [day, value] of Object.entries(bookedTime.values)) {
		if (moment.utc(selectedTime).isSame(moment(day), 'day')) {
			for (const index in value) {
				const time = bookedTime.values[day][index];

				if (moment(selectedTime).utc().format('HH:mm:ss') === time?.start) {
					return Math.floor(+index / offset) * offset;
				}
			}
		}
	}

	return 0;
};

const DoctorBookedTimeBlockTable: React.FC<ComponentProps> = ({
	bookedTime,
	onSelectTime,
	onSelectPreviousWeek,
	onSelectNextWeek,
	startDate,
	locationId,
	locations,
	onSelectLocation,
	className,
	rowCount,
	selectedTime,
	withoutLocationButtons,
	withHeader = true,
	nearestTime = null,
	onNearestTimeClick,
	range,
}) => {
	const [offset, setOffset] = React.useState<number>(rowCount ?? 7);
	const [count, setCount] = React.useState<number>(getInitialCount(bookedTime, offset, selectedTime));
	const [maxCount, setMaxCount] = React.useState<number>(() => {
		return Math.max(...Object.keys(bookedTime.values).map((day) => bookedTime.values[day]?.length ?? 0));
	});

	React.useEffect(() => {
		if (rowCount && rowCount !== offset) {
			setOffset(rowCount);
			setCount(0);
		}
	}, [rowCount]);

	React.useEffect(() => {
		setMaxCount(Math.max(...Object.keys(bookedTime.values).map((day) => bookedTime.values[day]?.length ?? 0)));
	}, [bookedTime]);

	const showMore = () => {
		setCount(count + offset);
	};

	const showPrev = () => {
		setCount(count - offset);
	};

	const renderItem = (index: number): Array<React.ReactNode> => {
		return Object.keys(bookedTime.values).slice(0, range).map((day) => {
			const time = bookedTime.values[day][index];
			const isSelected = !!selectedTime && moment.utc(selectedTime).isSame(moment(day), 'day')
				&& moment(selectedTime).utc().format('HH:mm:ss') === time?.start;

			return time ? <td key={day + index}>
				<button
					className={`btn ${isSelected ? 'btn-primary' : 'btn-default'}`}
					type="button"
					disabled={time.booked}
					onClick={(e) => {
						onSelectTime(moment(day).utc(), moment(time.start, 'hh:mm a'), { key: day, start: time.start });
					}}
				>
					<div>{moment(time.start, 'hh:mm a').format('hh:mm a')}</div>
				</button>
			</td> : <td key={day + index}><b>-</b></td>;
		});
	};

	const renderItems = () => {
		let index = count;
		const result: Array<any> = [];
		let row;

		do {
			row = renderItem(index++);

			result.push(<tr key={index}>
				<td />
				{row}
				<td />
			</tr>);
		} while (index < (count + offset > maxCount ? maxCount : count + offset));

		return result.map((node) => node);
	};

	const leftChevronDisabled = moment(startDate).utc() <= moment(new Date()).utc();

	return <div className={`${className ?? 'text-center'} doctor-booked-time is-relative`}>
		<div>
			{!withoutLocationButtons && locations && locations.length && locations.map((location) =>
				<button
					type="button"
					key={location.id}
					className={`btn ${location.id === locationId ? 'btn-primary' : 'btn-default'}`}
					onClick={(e) => onSelectLocation && onSelectLocation(location)}
				>
					{`${location.company?.name ? `${location.company?.name} - ` : ''}${location.nameEn}`}
				</button>)
			}
		</div>
		<table className="ant-table">
			<tbody className="ant-table-tbody">
				{withHeader && <DoctorBookedTimeBlockHeader
					leftChevronDisabled={leftChevronDisabled}
					onSelectPreviousWeek={onSelectPreviousWeek}
					onSelectNextWeek={onSelectNextWeek}
					columns={bookedTime.values}
					daysRange={range}
				/>}
				{!nearestTime && renderItems()}
			</tbody>
		</table>
		{nearestTime && <button
			type="button"
			className="btn btn-default text-center nearest-btn"
			onClick={onNearestTimeClick && onNearestTimeClick}
		>
			Nearest available on
			{' '}
			{moment(nearestTime).utc().format('MMM DD')}
		</button>}
		{!nearestTime && <>
			<button
				type="button"
				className="btn text-primary text-center doctor-booked-time__button doctor-booked-time__button__left"
				onClick={showMore}
				disabled={count + offset >= maxCount}
				title="Next times"
			>
				<i className="fa fa-chevron-down" aria-hidden="true" />
			</button>
			<button
				type="button"
				className="btn text-primary text-center doctor-booked-time__button doctor-booked-time__button__right"
				onClick={showPrev}
				disabled={count === 0}
				title="Previous times"
			>
				<i className="fa fa-chevron-up" aria-hidden="true" />
			</button>
		</>}
	</div>;
};

export default DoctorBookedTimeBlockTable;
