import * as React from 'react';

import Message from 'antd/lib/message';

import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { ItemsProvider, SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { EditableTable } from '@common/react/components/Core/EditableTable/EditableTable';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { BaseTestAnswer } from '@commonTuna/react/objects/BaseTestSession';
import { QuestionType } from '@commonTuna/react/objects/BaseDisease';
import { dateTimeFormatString, dateToUtcString } from '@commonTuna/react/components/Utils';
import { BasePatient } from '@commonTuna/react/objects/BasePatient';

interface ComponentProps {
	tempPatient: BasePatient | null;
	updateTempPatient: (value: BasePatient | null) => void;
	questionId: number;
}

const columns = [
	{
		title: 'Date',
		dataIndex: 'time',
		view: (text, record: BaseTestAnswer): JSX.Element | null => {
			if (record.time && record.testSession && record.testSession.appointmentId > 0) {
				return <LinkWithPrevLocation
					title="Start"
					to={`/appointment-wizard/${record.testSession.appointmentId}`}
				>
					{dateToUtcString(record.time, dateTimeFormatString)}
				</LinkWithPrevLocation>;
			}
			return null;
		},
		sorter: true,
	},
	{
		title: 'Answers',
		dataIndex: 'answers',
		view: (text, record: BaseTestAnswer): JSX.Element | null => {
			if (record.questionType === QuestionType.YesNo) {
				return <div>
					{record.answerBool ? 'Yes' : 'No'}
				</div>;
			}
			if (record.questionType === QuestionType.Text) {
				return <div>
					{record.answerText}
				</div>;
			}
			if (record.questionType === QuestionType.Number) {
				return <div>
					{record.answerNumber}
				</div>;
			}
			if (record.questionType === QuestionType.Single || record.questionType === QuestionType.Multiple) {
				let choicesString = '';
				record.choices.map((choice) => {
					if (choice.selected) {
						if (choicesString.length === 0) {
							choicesString = `${choice.answerText}`;
						} else {
							choicesString = `${choicesString}, ${choice.answerText}`;
						}
					}
					return choice;
				});
				return <div>
					{choicesString}
				</div>;
			}
			return null;
		},
	},
];

export const AnswersHistory: React.FC<ComponentProps> = ({
	tempPatient,
	updateTempPatient,
	questionId,
}) => {
	return (
		<div style={{ width: 350 }}>
			<ItemsProvider
				type="testAnswer"
				loadRequest="testAnswerAnswersHistoryList"
				filters={{
					patientId: tempPatient ? tempPatient.id : -1,
					questionId,
					column: [{ caption: 'time', direction: SortingDirection.Descending }],
				}}
				onRequestError={Message.error}
			>
				{(context) => <>
					<Autocomplete<BasePatient>
						type="patientViewList"
						renderTitle={(item) => {
							if (item) {
								return `${item.lastName || ''} ${item.firstName || ''}`;
							}
							return '';
						}}
						onSelect={(value, option) => {
							updateTempPatient(option.props.item);
							context.actions.handleChange({ patientId: option.props.item.id });
						}}
						onChange={(value = '') => {
							if (value === '') {
								updateTempPatient(null);
								context.actions.handleChange({ patientId: -1 });
							}
						}}
						value={
							tempPatient
								? `${tempPatient.firstName} ${tempPatient.lastName}`
								: ''
						}
						loadOnFocus
						antdProps={{
							placeholder: 'First Name Last Name',
							dropdownStyle: { minWidth: 600 },
						}}
						params={{
							withLocation: true,
						}}
					/>
					<EditableTable
						antdProps={{ className: 'mt10' }}
						columns={columns}
						hidePagination
						bordered={false}
						readonly
						withColumnSettings={false}
					/>
				</>}
			</ItemsProvider>
		</div>
	);
};
