import React from 'react';

import moment from 'moment';

import { DoctorBookedValues } from '@app/objects/Doctor';

interface Props {
	leftChevronDisabled: boolean;
	onSelectPreviousWeek: () => void;
	onSelectNextWeek: () => void;
	columns: DoctorBookedValues;
	disabledButtons?: boolean;
	daysRange?: number;
}

const DoctorBookedTimeBlockHeader: React.FC<Props> = ({
	leftChevronDisabled,
	onSelectNextWeek,
	onSelectPreviousWeek,
	columns,
	disabledButtons,
	daysRange,
}) => {
	return (
		<tr className="table__header">
			<th className="text-primary">
				<button
					className="btn fa fa-chevron-left doctor-booked-time__button"
					title="Previous week"
					type="button"
					disabled={disabledButtons || leftChevronDisabled}
					onClick={onSelectPreviousWeek}
				/>
			</th>
			{Object.keys(columns).slice(0, daysRange).map((day, index) =>
				<th
					key={day}
					className="text-primary"
					style={{ padding: '5px' }}
				>
					{moment(day).utc().format('ddd')}
					<br />
					{moment(day).utc().format('MMM DD')}
				</th>)}
			<th className="text-primary">
				<button
					className="btn fa fa-chevron-right doctor-booked-time__button"
					title="Next week"
					type="button"
					aria-hidden="true"
					disabled={disabledButtons}
					onClick={onSelectNextWeek}
				/>
			</th>
		</tr>
	);
};

export default DoctorBookedTimeBlockHeader;
