import moment from 'moment';

import { DoctorBookedTime } from '@app/objects/Doctor';
import { getMinutesFromDuration } from '@app/components/UI/ScheduleAppointmentModal/ScheduleAppointment';

const dayMS = 3600 * 1000 * 24; // milliseconds in one day

export const getStartDayInitial = (range: number, selectedTime?: number): number => {
	const now = moment.utc().valueOf();

	if (!selectedTime) {
		return now;
	}

	return now + Math.floor((selectedTime - now) / (dayMS * range)) * dayMS * range;
};

export const filterDisabledTimes = (times: DoctorBookedTime): DoctorBookedTime => {
	const res = { ...times, values: { ...times.values } };

	Object.entries(res.values).forEach(([key, blocks]) => {
		res.values[key] = blocks.filter(({ booked }) => !booked);
	});

	return res;
};

export const filterTimesBeforeNow = (times: DoctorBookedTime): DoctorBookedTime => {
	const res = { ...times, values: { ...times.values } };
	const now = moment(new Date());

	Object.entries(res.values).forEach(([key, blocks]) => {
		if (moment(key).utc().isSame(now, 'day')) {
			res.values[key] = blocks.filter(({ start }) => moment(start, 'HH:mm:ss').valueOf() >= now.valueOf());
		}
	});

	return res;
};

export const changeBookedTimesByInterval = (times: DoctorBookedTime, interval: number): DoctorBookedTime => {
	const res = { ...times, values: { ...times.values } };

	Object.entries(res.values).forEach(([key, blocks]) => {
		res.values[key] = blocks.map((currentBlock, index) => {
			let duration = getMinutesFromDuration(currentBlock.end) - getMinutesFromDuration(currentBlock.start);

			if (duration >= interval || currentBlock.booked) {
				return currentBlock;
			}

			for (let i = index; blocks.length > i; i++) {
				if (duration < interval
						&& (!blocks[i + 1] || blocks[i].end !== blocks[i + 1]?.start || blocks[i + 1].booked)) {
					return { ...currentBlock, booked: true };
				}

				duration += getMinutesFromDuration(blocks[i + 1].end) - getMinutesFromDuration(blocks[i + 1].start);
				if (duration >= interval) {
					return currentBlock;
				}
			}

			return { ...currentBlock, booked: duration < interval };
		});
	});

	return res;
};

export const resolveBookedTimes = (times: DoctorBookedTime, interval: number): DoctorBookedTime => {
	return changeBookedTimesByInterval(filterTimesBeforeNow(times), interval);
};
