import * as React from 'react';
import { Link } from 'react-router-dom';

import Modal from 'antd/lib/modal';

import LoginPage from '@common/react/components/Pages/Login/Login';

import { customReduxActions } from '@app/store/CustomReduxActions';

interface Props {
	visible: boolean;
	handleClose: () => void;
	afterLogin: () => void;
}

const LoginForm: React.FC<Props> = ({ visible, handleClose, afterLogin }) => {
	return (
		<Modal
			title={null}
			centered
			open={visible}
			onCancel={handleClose}
			footer={null}
			width={500}
			destroyOnClose
			maskClosable={false}
			className="portal-form-center"
			getContainer={() => document.querySelector('.app-container') || document.body}
		>
			<div className="enter-page__form general-form">
				<LoginPage
					request="authPortal"
					customReduxActions={customReduxActions}
					title="Login"
					withoutServerPage
					afterBaseRedirectUser={() => <></>}
					withFieldTitle
					buttons={<Link to="/registration" className="btn btn-default register-link">Register</Link>}
					afterLogin={afterLogin}
				/>
			</div>
		</Modal>
	);
};

export default LoginForm;
