import * as React from 'react';

import Autocomplete, { AutocompleteProps } from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { WithId } from '@common/typescript/objects/WithId';
import { HandleChangeEventElement } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

interface ComponentProps<T extends WithId = any> extends Partial<AutocompleteProps> {
	handleChangeAndRefresh: (target: HandleChangeEventElement, option?: any) => void;
	param: string;
	currentValue?: number | string | undefined | null;
	emptyValue?: number | string;
	className?: string;
}

type RenderTitleFunc<T> = (item: T) => React.ReactNode;

const AutocompleteFilter = <T extends WithId = any>(props: ComponentProps<T>): React.ReactElement<ComponentProps<T>> => {
	return (
		<div className={props.className || 'autocomplete-filter-component'}>
			<Autocomplete<T>
				{...props}
				renderTitle={props.renderTitle as keyof T | RenderTitleFunc<T>}
				onSelect={(value, option) => {
					props.handleChangeAndRefresh({
						currentTarget: {
							name: props.param,
							value: `${value}`,
						},
					}, option);
				}}
				antdProps={{
					onClear: () => {
						props.handleChangeAndRefresh({
							currentTarget: {
								name: props.param,
								value: -1,
							},
						});
					},
				}}
			/>
		</div>
	);
};

export default AutocompleteFilter;
